import React from 'react';
import { styled } from "linaria/react";
import Image from '@jetshop/ui/Image/Image';
import { Link } from 'react-router-dom';
import {dropdownColors} from './Hero';
import {theme} from '../Theme';

const ItemWrapper = styled('section')`
  padding: 0 5px;
  a {
    text-decoration: none;
    color: ${props => props.color};
  }
`;

const ImageWrapper = styled('div')`
  position: relative;
`;

const ContentWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  h3 {
    text-align: center;
    line-height: 1.3;
    letter-spacing: 0.03em;
    font-weight: 300;
    text-transform: uppercase;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  }
`;

export const LinkedImage = ({ text, link, contentColor, image, fontSize }) => {
  const imageAspect = '13:17';

  text = text?.value;
  image = image?.value;
  link = link?.value;
  fontSize = fontSize?.value ?? '18px';
  contentColor = dropdownColors[contentColor?.value] ?? theme.colors.white;

  return (
    <ItemWrapper className='item'>
      <Link to={link}>
        <ImageWrapper>
          <Image
            aspect={imageAspect}
            cover={true}
            src={image}
            sizes={'500'}
          />
          <ContentWrapper style={{
            fontSize: fontSize
          }}>
            {text && <h3 style={{color: contentColor}}>{text}</h3>}
          </ContentWrapper>
        </ImageWrapper>
      </Link>
    </ItemWrapper>
  );
};
import React from 'react';
import { styled } from 'linaria/react';
import { Query } from 'react-apollo';
import ProductBlockQuery from './ProductBlockQuery.gql';
import ProductGrid from '../CategoryPage/ProductGrid';
import { theme } from '../Theme';

const ProductGridWrapper = styled('div')`
  overflow: hidden;
  ${theme.below.md} {
    //overflow: auto;
  }

  h2 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #333333;
    font-weight: 300;
  }
  
  .product-grid {
    overflow-x: scroll;
    flex-wrap: nowrap;
    
    > li {
      min-width: 28%;
      ${theme.below.md} {
        min-width: 70%;
      }
    }
  }
`;

export const ProductBlock = ({ categoryId, heading, productQty }) => {

  if (!categoryId?.value) {
    return null;
  }
  categoryId = parseFloat(categoryId?.value);

  return (
    <React.Fragment>
      <Query query={ProductBlockQuery} variables={{ id: categoryId, first: parseInt(productQty?.value?? 12) }}>
        {({ loading, error, data }) => {
          if (loading) {
            return 'Loading...';
          }
          if (error) return null;

          if (data && data.category) {
            return (
              <ProductGridWrapper>
                {heading?.value && <h2>{heading.value}</h2>}
                <ProductGrid
                  products={data.category.products.result}
                  listName={data.category.name}
                  categoryPath={data.category.primaryRoute}
                  loading={loading}
                  className={'product-block'}
                />
              </ProductGridWrapper>
            );
          } else {
            return null;
          }
        }}
      </Query>
    </React.Fragment>
  );
};

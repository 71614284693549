import { useProductList } from '@jetshop/core/hooks/ProductList';
import React from 'react';
import { css } from "linaria";
import { ReactComponent as HeartSVG } from '../../svg/Heart.svg';

const favourite = css`
  button {
    background: transparent;
    svg {
      filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2));
      &.active {
        fill: currentColor;
      }
      width: 24px;
      height: 24px;
      @media (min-width: 1000px) and (max-width: 1150px) {
        width: 1.3em;
        height: 1.3em;
      }
    }
    &:focus {
      outline: none;
    }
  }
`;

export function Favourite({ product, variant, ...rest }) {
  const articleNumber = product?.articleNumber;

  //const variationContext = useContext(ProductVariationContext);
  //const selectedVariation =
  //  product.variant || variant || variationContext?.selectedVariation;
  const selectedVariation = product.variant || variant;
  const variantArticleNumber = selectedVariation?.articleNumber;

  const { toggle, inList } = useProductList();

  function toggleWithoutBubble(e) {
    e.preventDefault();
    toggle(articleNumber, {
      variantArticleNumber
    });
  }

  const isInList = inList(articleNumber, {
    variantArticleNumber
  });

  return (
    <div className={favourite} {...rest}>
      <button onClick={toggleWithoutBubble}>
        <HeartSVG className={isInList ? 'active' : 'inactive'} />
      </button>
    </div>
  );
}

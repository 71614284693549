import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';

const StyledMaxWidth = styled(MaxWidth)`
  padding: 0 5px;
  margin: 0 auto;
`;

const Container = styled('div')``;

const ItemsContainer = styled('div')`
  display: flex;
  padding: 0 5px;

  ${theme.below.lg} {
    flex-wrap: wrap;
    justify-content: center;
  }

  ${theme.below.md} {
    overflow-x: auto;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &.items-2 {
    .item {
      width: 50%;
    }
  }

  &.items-3 {
    .item {
      width: 33.333%;

      ${theme.below.md} {
        width: 50%;

        &:first-child {
          width: 100%;
          margin-bottom: 10px;
        }

      }

    }
  }

  &.items-4 {
    .item {
      width: 50%;

      ${theme.below.lg} {
        &:first-child,
        &:first-child + * {
          margin-bottom: 10px;
        }

      }
    }
  }
`;

export const LinkedImageRow = ({ spaceAbove, spaceBelow, children }) => {
  spaceAbove = spaceAbove?.value;
  spaceBelow = spaceBelow?.value;
  return (
    <StyledMaxWidth>
      <Container style={{
        paddingTop: spaceAbove ?? '0',
        paddingBottom: spaceBelow ?? '0'
      }}>
        <ItemsContainer className={`items-${children.length}`}>
          {children}
        </ItemsContainer>
      </Container>
    </StyledMaxWidth>
  );
};


import { TextField, TextFieldItem } from './TextField';
import { AccordionField, AccordionItem } from './AccordionField';
import { ArticleList, ArticleListItem } from './ArticleList';
import { HtmlField } from './HtmlField';
import { SingleImage } from './SingleImage';
import { Spacer } from './Spacer';
import { ProductBlock } from './ProductBlock';
import { Hero } from './Hero';
import { CtaButton } from './CtaButton';
import { LinkedImageRow } from './LinkedImageRow';
import { LinkedImage } from './LinkedImage';
import { ImageAndText } from './ImageAndText';
import { Video } from '../StartPage/Content/StartPageVideo';

export const allContentComponents = {
  ACCORDIONFIELD: AccordionField,
  ACCORDIONITEM: AccordionItem,
  TEXTFIELD: TextField,
  TEXTFIELDITEM: TextFieldItem,
  ARTICLELIST: ArticleList,
  ARTICLELISTITEM: ArticleListItem,
  HTMLFIELD: HtmlField,
  'SINGLE IMAGE': SingleImage,
  SPACER: Spacer,
  'PRODUCT BLOCK': ProductBlock,
  HERO: Hero,
  'CTA BUTTON': CtaButton,
  'LINKED IMAGE ROW': LinkedImageRow,
  'LINKED IMAGE': LinkedImage,
  'IMAGE AND TEXT': ImageAndText,
  VIDEO: Video
};

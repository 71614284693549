import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { Link } from 'react-router-dom';
import { dropdownColors } from './Hero';

const StyledButton = styled(Link)`
  margin: 10px;
  display: inline-block;
  text-decoration: none;
  padding: 0.6rem 0.8rem;
  font-size: 14px;
  font-weight: 400;
  transition: all, 0.2s ease;
  letter-spacing: 0.15em;
  border: 1px solid #000;


  &.inverted {
    text-shadow: none;
  }

`;

const contrastColors = {
  'Black': theme.colors.white,
  'White': theme.colors.black,
  'Light beige': theme.colors.black,
  'Dark beige': theme.colors.white
};

export const CtaButton = (
  {
    text,
    link,
    color,
    inverted
  }
) => {

  text = text?.value;
  link = link?.value;
  color = color?.value;
  inverted = inverted?.value;

  const bgColor = dropdownColors[color] ?? theme.colors.white;
  const textColor = inverted ? contrastColors[color] : bgColor;

  return (
    <StyledButton
      className={inverted ? 'inverted' : null}
      style={{
        backgroundColor: inverted ? bgColor : 'transparent',
        color: textColor,
        borderColor: bgColor
      }}
      to={link}
    >
      {text}
    </StyledButton>
  );
};
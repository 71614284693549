import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';


const TextFieldWrapper = styled('div')`
  max-width: 44rem;
  padding: 0 20px;
  margin: auto;
  letter-spacing: 0.02em;


  h1, h2, h3, h4 {
    //color: ${theme.colors.darkBeige};
    //text-transform: uppercase;
    font-weight: 300;
    margin: 1rem 0 0;
    margin-bottom: -0.25rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.3;
    margin: 0.5rem 0 0;
  }

  a {
    text-decoration: underline;
    color: ${theme.colors.black};
  }

  &:first-child {
    margin-top: 0;
  }
`;

export const TextField = ({ children, title }) => {



  return (
    <TextFieldWrapper>
      <h3>{title?.value}</h3>
      {children}
    </TextFieldWrapper>
  );
};

export const TextFieldItem = ({ content }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: content?.value
      }}
    />
  );
};

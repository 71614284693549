import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import { Above } from '@jetshop/ui/Breakpoints';
import {dropdownColors} from './Hero';

import { theme } from '../Theme';

const ImageAndTextWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90rem;
  width: 100%;

  ${theme.below.xl} {
  }

  ${theme.below.md} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const ImageContainer = styled('div')`
  width: 50%;

  ${theme.below.md} {
    width: 100%;
  }
`;

const TextContainer = styled('article')`
  width: 50%;
  padding: 0 1rem;
  max-width: 30rem;
  margin: 0 auto;
  background: ${theme.colors.white};


  ${theme.below.md} {
    width: calc(100% - 2rem);
    margin-top: -6rem;
    position: relative;
    z-index: 1;
    padding-top: 1.125rem;
  }
  
  p {
    text-align: center;
    line-height: 1.4;
    margin-top: 1.25rem;

  }
`;

export const Heading = styled('h2')`
  font-size: 50px;
  line-height: 1;
  text-align: center;
  max-width: 750px;
  margin: 0;
  font-weight: 300;
  letter-spacing: 0.03em;

  ${theme.below.md} {
    
  }
`;

const ImageAndTextImage = styled(Image)``;


const Buttons = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  ${theme.below.md} {
    a {
    }
  }
`;


export const ImageAndText = (
  {
    mainImage,
    mainAspect,
    mobileImage,
    mobileAspect,
    heading,
    headingSize,
    text,
    textSize,
    contentColor,
    children
  }
) => {
  contentColor = dropdownColors[contentColor?.value] ?? theme.colors.black;

  const mobileAspectToUse = mobileImage && mobileAspect ?
    mobileAspect.value : mobileImage ? '3:4' : mainAspect.value;
  headingSize = headingSize.value ?? '50px';
  const mobileHeadingSize = parseInt(headingSize) / 5.7 + 'vw';


  return (
    <ImageAndTextWrapper>
      <Above breakpoint='md'>
        {matches =>
          (matches || !matches) && (
            <>
            <ImageContainer>
              <ImageAndTextImage
                src={matches ? mainImage : mobileImage ?? mainImage}
                sizes={matches ? '2000' : '800'}
                cover
                aspect={matches ? mainAspect.value : mobileAspectToUse}
              />
            </ImageContainer>
              <TextContainer>
                {/*<VertAlign height={mobile ? mobileHeight : desktopHeight}>*/}

                  {heading?.value && <Heading
                    color={contentColor}
                    style={{
                      color: contentColor ?? theme.colors.black,
                      fontSize: matches ? headingSize : mobileHeadingSize
                    }}>
                    {heading.value}
                  </Heading>}

                  {text?.value && <p style={{
                    color: contentColor ?? theme.colors.black,
                    fontSize: textSize?.value ?? '28px'
                  }}>
                    {text.value}
                  </p>}
                  <Buttons>
                    {children}
                  </Buttons>
              </TextContainer>
            </>
          )
        }
      </Above>
    </ImageAndTextWrapper>
  );
};
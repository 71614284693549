import { styled } from 'linaria/react';
import React from 'react';
import qs from 'qs';
import useCleanProps from '../../../hooks/useCleanProps';
import { theme } from "../../Theme";
import { Above } from '@jetshop/ui/Breakpoints';


const VertAlign = styled('div')`
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${theme.below.md} {
    left: 6px;
    right: 6px;
  }
`;

const TextContainer = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  h1 {
    line-height: 1;
  }
`;

const EmbedContainer = styled.section`
  position: relative;
  width: 100%;

  section& {
    margin-bottom: 2rem;
  }

  .video-wrapper {
    padding-bottom: ${({ aspect }) => aspect};
  }

  video,
  iframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

const Buttons = styled('div')`
  display: flex;
  justify-content: center;

  ${theme.below.md} {
    a {
      margin: 10px 6px;
      padding: 0.6rem;
    }
  }
`;

function getAspectRatio(aspect) {
  switch (aspect) {
    case '16/9':
      return '56.25%';
    case '1/1':
      return '100%';
    case '3/4':
      return '133.33%';
    default:
      return '56.25%';
  }
}

const base = {
  Youtube: ({ id, autoplay, controls, loop = false }) => {
    const params = {
      mute: autoplay ? 1 : 0,
      autoplay: autoplay ? 1 : 0,
      loop: loop ? 1 : 0,
      controls: controls ? 1 : 0,
      playsinline: 1
    };

    return `https://www.youtube.com/embed/${id}?${qs.stringify(params)}`;
  },
  Vimeo: ({ id, autoplay, controls }) => {
    const params = {
      muted: autoplay ? 1 : 0,
      autoplay: autoplay ? 1 : 0,
      controls: controls ? 1 : 0,
      playsinline: 1
    };

    return `https://player.vimeo.com/video/${id}?${qs.stringify(params)}`;
  }
};

export const dropdownColors = {
  'Black': theme.colors.black,
  'White': theme.colors.white,
  'Light beige': theme.colors.lightBeige,
  'Dark beige': theme.colors.darkBeige
};

export const Video = props => {
  const aspect = getAspectRatio(props.aspect?.value);
  const mobileAspect = getAspectRatio(props.mobileAspect?.value);
  const {
    contentColor,
    heading,
    headingSize,
    children
  } = useCleanProps(props);
  const headingColor = dropdownColors[contentColor] ?? theme.colors.white;

  return (
    <Above breakpoint='md'>
      {matches => (matches || !matches) && (
        <EmbedContainer
          aspect={matches ? aspect : mobileAspect}
        >
          <div className="video-wrapper">
              <TextContainer>
                <VertAlign>
                  {heading && <h1 style={{ color: headingColor, fontSize: headingSize }}>{heading}</h1>}
                  <Buttons>
                    {children}
                  </Buttons>
                </VertAlign>
              </TextContainer>
              <ExternalVideo isDesktop = {matches} {...props} /> 
          </div>
        </EmbedContainer>
      )}
    </Above>
  );
};

export const ExternalVideo = ({ isDesktop, videoType, videoId, mobileVideoType, mobileVideoId, controls, autoplay,  }) => {
  const type = isDesktop ? videoType.value : mobileVideoType.value;
  const src = base?.[type]({
    id: isDesktop ? videoId.value : mobileVideoId.value,
    autoplay: autoplay.value,
    controls: controls.value
  });

  return (
    <iframe
      src={src}
      frameBorder="0"
      title="Embedded video"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop;"
      allowFullScreen
    ></iframe>
  );
};

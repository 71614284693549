import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../Theme';

const ArticleListWrapper = styled('div')`
  padding: 20px 20px;
  margin: auto;

  h3 {
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    line-height: 28px;
    margin-bottom: 12px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #787878;
  }
  a {
    text-decoration: underline;
    color: #000;
  }
`;

const ListWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1026px;
  margin: 0 auto;
`;

const ArticleWrapper = styled('div')`
  width: 31.733%;
  margin: 0.8%;
  a {
    text-decoration: none;
  }
`;

const ArticleInner = styled('div')`
  position: relative;
  .imageText {
    position: absolute;
    top: 5px;
    color: white;
    width: 100%;
    p {
      color: white;
    }
  }
  .topic {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.012em;
    color: ${theme.colors.darkBeige};
    display: block;
    text-align: center;
    padding: 15px 0 0 0;

  }
  .date {
    font-size: 8px;
    font-weight: 400;
    color: ${theme.colors.darkgrey};
    display: block;
    text-align: center;
    padding: 0;
  }
  .desc {
    font-size: 13px;
    font-weight: 400;
    color: ${theme.colors.black};
    display: block;
    text-align: center;
    padding: 5px 0 0 0;
    line-height: 1.2;
  }
`;

export const ArticleList = ({ children, title }) => {
  return (
    <ArticleListWrapper>
      <h3>{title?.value}</h3>
      <ListWrapper>
        {children.map((child, index) => (
          <ArticleListItem
            key={index}
            article={child} />
        ))}
      </ListWrapper>
    </ArticleListWrapper>
  );
};

export const ArticleListItem = ({ article }) => {

  return (
    <ArticleWrapper>
        <a href='/'>
          <ArticleInner>
            <Image cover aspect={'325:183'} src={article.props.image?.value}  />
            <div 
              className='imageText'
              dangerouslySetInnerHTML={{
                __html: article.props.imageText?.value
              }}
            />
            <span className='topic'>{article.props.topic?.value}</span>
            <span className='date'>{article.props.date?.value}</span>
            <span className='desc'>{article.props.description?.value}</span>
          </ArticleInner>
        </a>
    </ArticleWrapper>
  );
};

import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import Image from '@jetshop/ui/Image/Image';
import { Above } from '@jetshop/ui/Breakpoints';

import { theme } from '../Theme';

const HeroWrapper = styled('div')`
  position: relative;

  ${theme.below.xl} {
    padding: 0;
  }

  ${theme.below.md} {
    padding: 0;
  }

  img {
    object-position: top center !important;
  }
`;
export const Container = styled(MaxWidth)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.6);

  p {
    font-size: 28px;
    line-height: 1.2;
    max-width: 750px;
    margin: 0 auto;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    letter-spacing: 0.03em;
    text-align: center;

    ${theme.below.md} {
      margin-top: -5px;
      font-size: 20px;
      line-height: 23px;
    }
  }
`;
export const Heading = styled('h1')`
  font-size: 50px;
  line-height: 1;
  text-align: center;
  max-width: 750px;
  margin: 0;
  font-weight: 300;
  letter-spacing: 0.03em;

  ${theme.below.md} {
    font-size: 28px;
    max-width: 80vw;
  }
`;

const HeroImage = styled(Image)``;

const VertAlign = styled('div')`
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
    // height: ${props => props.height};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${theme.below.md} {
    left: 6px;
    right: 6px;
  }
`;

const Buttons = styled('div')`
  display: flex;
  justify-content: center;

  ${theme.below.md} {
    a {
      margin: 10px 6px;
      padding: 0.6rem;
    }
  }
`;

export const dropdownColors = {
  'Black': theme.colors.black,
  'White': theme.colors.white,
  'Light beige': theme.colors.lightBeige,
  'Dark beige': theme.colors.darkBeige
};

export const Hero = (
  {
    mainImage,
    mainAspect,
    mobileImage,
    mobileAspect,
    heading,
    headingSize,
    text,
    textSize,
    contentColor,
    children
  }
) => {

  contentColor = dropdownColors[contentColor?.value] ?? theme.colors.white;

  const mobileAspectToUse = mobileImage && mobileAspect ?
    mobileAspect.value : mobileImage ? '3:4' : mainAspect.value;
  headingSize = headingSize.value ?? '50px';
  const mobileHeadingSize = parseInt(headingSize) / 5.7 + 'vw';


  return (
    <HeroWrapper>
      <Above breakpoint='md'>
        {matches =>
          (matches || !matches) && (
            <div style={{
              height: matches ? '600px' : 'auto'
            }}>
              <HeroImage
                fillAvailableSpace={matches ? true : false}
                src={matches ? mainImage : mobileImage ?? mainImage}
                sizes={matches ? '2000' : '800'}
                cover
                aspect={matches ? mainAspect.value : mobileAspectToUse}
              />
              <Container>
                {/*<VertAlign height={mobile ? mobileHeight : desktopHeight}>*/}
                <VertAlign>

                  {heading?.value && <Heading
                    color={contentColor}
                    style={{
                      color: contentColor ?? theme.colors.black,
                      fontSize: matches ? headingSize : mobileHeadingSize
                    }}>
                    {heading.value}
                  </Heading>}

                  {text?.value && <p style={{
                    color: contentColor ?? theme.colors.black,
                    fontSize: textSize?.value ?? '28px'
                  }}>
                    {text.value}
                  </p>}
                  <Buttons>
                    {children}
                  </Buttons>
                </VertAlign>
              </Container>
            </div>
          )
        }
      </Above>
    </HeroWrapper>
  );
};
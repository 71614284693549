import React from 'react';
import { styled } from 'linaria/react';
import { Below } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import { Link } from 'react-router-dom';

const ImageContainer = styled('div')`

  &.limit-width {
    max-width: 44rem;
    margin: 0 auto;
    padding: 0 20px;
  }

  .std-image {
    max-width: 100%;
  }
`;

const LinkElement = ({ link, children }) => {
  return link?.value ? (<Link to={link.value}>{children}</Link>) : children;
};

export const SingleImage = (
  {
    image,
    aspect,
    mobileImage,
    mobileAspect,
    imageLayout,
    imageWidth,
    spaceAbove,
    spaceBelow,
    link
  }
) => {

  const cover = imageLayout?.value.match(/cover/ig) ? true : false;
  const freeform = imageLayout?.value.match(/free/ig) ? true : false;
  return (
    <ImageContainer
      style={{
        paddingTop: spaceAbove?.value ? spaceAbove.value : '0',
        paddingBottom: spaceBelow?.value ? spaceAbove.value : '0'
      }}
      className={imageWidth?.value.match(/limited/ig) ? 'limit-width' : ''}
    >
      <LinkElement link={link}>
        {freeform ? ( // Regular image element for non-standard images
          <Below breakpoint='md'>
            {matches =>
              matches && mobileImage?.value ? (
                <img className='std-image' src={mobileImage?.value} alt={''} />
              ) : (
                <img className='std-image' src={image?.value} alt={''} />

              )}
          </Below>
        ) : ( // Flight Image Component
          <Below breakpoint='md'>
            {matches =>
              matches && mobileImage?.value ? (
                <Image aspect={mobileAspect?.value ?? aspect?.value} src={mobileImage?.value} cover={cover}
                       sizes={[1, 1, 1, 1, 1, 1, 1 / 2]} />
              ) : (
                <Image aspect={aspect?.value} src={image?.value} cover={cover} sizes={[1, 1, 1, 1, 1, 1, 1 / 2]} />

              )}
          </Below>
        )}
      </LinkElement>
    </ImageContainer>

  );
};

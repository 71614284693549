import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { styled } from "linaria/react";
import { cx } from "linaria";
import { ProductCard } from './ProductCard';
import { theme } from '../Theme';
import { Favourite } from '../ProductList/Favourite';

export const priceStyle = `
  .new {
    color: #ff0000;
    margin-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .old {
    color: ${theme.colors.grey};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Wrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: stretch;
  margin-right: -${theme.space[1]};
  margin-left: -${theme.space[1]};
  scrollbar-width: thin;
  scrollbar-color: transparent;

  ::-webkit-scrollbar {
    display: none;
  }

  ${priceStyle};

  .product-card {
    line-height: 19px;
    min-width: 0;
    width: 48%;
    ${theme.above.md} {
      width: 33.333%;
    }

    ${theme.above.lg} {
      width: 33%;
    }

    a {
      margin: 0 0.5em 2em 0;
    }
  }

  &.product-block {

    .product-card {
      width: 50%;

      ${theme.above.md} {
        width: 33.333%;
      }
    }

  }

  .carousel {
    .product-card {
      a {
        margin-bottom: 0px;
      }
    }
  }
`;

const FavouriteWrapper = styled('div')`
  display: contents;

  > div {
    position: absolute;
    font-size: 1.5em;
    top: 5px;
    right: 15px;
    z-index: 2;
  }

  svg {
    color: white;
    &.active {
      fill: currentColor;
    }
  }
`;

export function ProductGrid({
  products,
  listName,
  loading,
  className,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <Wrapper
      data-testid="product-grid"
      className={cx('product-grid', className)}
    >
      {products && products.length && products.map((product, index) => {
        return product.primaryRoute ? (
          <ProductCard
            key={index + ':' + product.articleNumber}
            product={product}
            style={{ opacity: loading ? 0.5 : 1 }}
            list={listName}
            {...rest}
          >
            <FavouriteWrapper>
              <Favourite product={product} />
            </FavouriteWrapper>
          </ProductCard>
        ) : null;
      })}
    </Wrapper>
  );
}

export default ProductGrid;
